import React from 'react';
import '../CSS/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-side-by-side">
        <div className="footer-logo">
          <span><b>Singapore Ancestry</b></span>
          {/* <img src="/path-to-your-logo.png" /> */}
        </div>
        <div className="footer-navbar">
          <ul>
            <li><a href="#home">Obituary</a></li>
            <li><a href="#obituary">Gallery</a></li>
            <li><a href="#gallery">Contact Us</a></li>
          </ul>
        </div>
      </div>
      <hr />
      <p>&copy; {new Date().getFullYear()} Name. All rights reserved.</p>
    </footer>
  );
}

export default Footer;