import '../CSS/RecentObituaries.css';

const obituaries = [
  {
      name: "Michael Jackson",
      dates: "Aug 29, 1958 - June 25, 2009",
      image: "mj.png" // Replace with actual image URL
  },
  {
      name: "Leslie Cheung",
      dates: "Sep 12, 1956 - Apr 1, 2003",
      image: "leslie.png" // Replace with actual image URL
  },
  {
      name: "Robin Williams",
      dates: "Jul 21, 1951 - Aug 11, 2014",
      image: "robin.png" // Replace with actual image URL
  },
  {
      name: "Coco Lee",
      dates: "Jan 17, 1975 - Jul 5, 2023",
      image: "cocolee.png" // Replace with actual image URL
  }
];

const ForArrow = () => {
  if (window.innerWidth >= 768) {
    return (
      <div className="recent-obituaries-obituaries-container">
        <button className="recent-obituaries-nav-button"><img src='left arrow.png' alt='an arrow' /></button>
        {obituaries.map((obit, index) => (
          <div className="recent-obituaries-obituary-card" key={index}>
            <img src={obit.image} alt={obit.name} className="recent-obituaries-obituary-image" />
            <h3>{obit.name}</h3>
            <p className="recent-obituaries-obituary-dates">{obit.dates}</p>
          </div>
        ))}
        <button className="recent-obituaries-nav-button"><img src='right arrow.png' alt='an arrow' /></button>
      </div>
    )
  } else {
    return (
      <div className="recent-obituaries-obituaries-container">
        {obituaries.map((obit, index) => (
          <div className="recent-obituaries-obituary-card" key={index}>
            <img src={obit.image} alt={obit.name} className="recent-obituaries-obituary-image" />
            <h3>{obit.name}</h3>
            <p className="recent-obituaries-obituary-dates">{obit.dates}</p>
          </div>
        ))}
        <div className='recent-obituaries-nav-button'>
          <button className="recent-obituaries-nav-button"><img src='left arrow.png' alt='an arrow' /></button>
          <button className="recent-obituaries-nav-button"><img src='right arrow.png' alt='an arrow' /></button>
        </div>
      </div>
    )
  }
}

const RecentObituaries = () => {
  return (
      <div className="recent-obituaries">
        <div className='recent-obituaries-text'>
          <h2 className='recent-obituaries-h2'>Recent Obituaries</h2>
          <p className='recent-obituaries-p'>View the latest obituaries, read life stories, and share tributes and condolences.</p>
          <p className='recent-obituaries-p'><a href="/obituaries" className="recent-obituaries-see-all">See all obituaries</a></p>
        </div>
        <div className='place-in-column'>
          <ForArrow />
        </div>
      </div>
  );
};

export default RecentObituaries;