import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Obituary from './Obituary';
import Gallery from './Gallery';
import Header from './Component/Header';
import Footer from './Component/Footer';
// import LinkPreview from './Component/LinkPreview';

import FullScreenSlideshow from './FullScreenSlideshow';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/fss" element={<FullScreenSlideshow />} />
      </Routes>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/obituary" element={<Obituary />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
