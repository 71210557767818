import '../CSS/JoinUs.css';

function JoinUs() {
  return (
    <div className="JoinUs">
      <div className="joinUs-big-text">
        <span>Create an Online Obituary</span>
      </div>
      <div className="joinUs-small-text">
        <span>Join our community and start creating online obituary for your loved ones today.</span>
      </div>
      <div className='joinUs-getStartedBtn'>
        <button>
          Get started
        </button>
      </div>
    </div>
  );
}

export default JoinUs;