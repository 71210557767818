import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import "./CSS/FullScreenSlideshow.css";

const FullScreenSlideshow = () => {
  const location = useLocation();
  const state = location.state;

  let images = [];

  state.props.map((prop, index) => (
    images.push(prop)
  ))

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  // let elem = document.getElementById("slideshow");
  // function openFullscreen() {
  //   if (elem.requestFullscreen) {
  //     elem.requestFullscreen();
  //   } else if (elem.webkitRequestFullscreen) { /* Safari */
  //     elem.webkitRequestFullscreen();
  //   } else if (elem.msRequestFullscreen) { /* IE11 */
  //     elem.msRequestFullscreen();
  //   }
  // }

  return (
    <div className="slideshow-container">
      {/* <button onClick="openFullscreen();">Open Video in Fullscreen Mode</button>
      <p><strong>Tip:</strong> Press the "Esc" key to exit full screen.</p> */}
      <div className="slideshow" style={{ backgroundImage: `url(${images[currentIndex]})` }} id="slideshow"></div>
    </div>
  );
};

export default FullScreenSlideshow;